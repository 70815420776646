<template>
  <div class="music">
    <div class="player">
      <div class="searchbar">
        <input ref="search" type="search" v-model="keyword">
      </div>
      <div class="info">
        <div class="music_list">
          <div class="list" v-for="(item,index) in musicList" v-bind:key="item.id" @click="play(index)"
               v-show="isshow(item.id)">
            <div class="title">{{ item.title }}</div>
            <div class="performer">{{ item.author }}</div>
            <div class="duration">{{ item.duration }}</div>
          </div>
        </div>
      </div>

      <div class="control">
        <div class="controlbtn">
          <div class="icon ri-skip-back-fill" @click="back"></div>
          <div class="icon ri-play-fill" @click="pause" ref="pause"></div>
          <div class="icon ri-skip-forward-fill" @click="next"></div>
        </div>
        <div class="progressbar">
          <div class="information">
            <a class="name">{{ name() }}</a>
            <div class="time">
              <a class="currentTime">{{ convert(currentTime) }} / </a>
              <a class="durationTime">{{ convert(audio.duration) }}</a>
            </div>
          </div>

          <div class="track" ref="track" @mousedown="changeProgress($event)">
            <div class="bar" :style="{width:progress*100+'%'}"></div>
            <div class="dot" :style="{left:progress*100+'%'}" @mousedown="dot_press"></div>
          </div>
        </div>
        <div class="controlbtn">
          <div class="icon ri-repeat-one-fill" @click="repeat($event)" ref="repeat"></div>
          <div class="volume" id="volume">
            <div class="icon ri-volume-down-line" @click="changevolume" ref="volume"></div>
            <div class="volumebox">
              <div class="vtrack" @mousedown="advolume($event)" ref="vtrack">
                <div class="vbar" :style="{width:volume*100+'%'}"></div>
                <div class="vdot" :style="{left: volume*100+'%'}" @mousedown="vdot_press"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Music',
  data() {
    return {
      baseurl: 'https://dboy9527.top/api/music?id=',
      // baseurl: 'http://127.0.0.1:8000/music?id=',
      musicList: [],
      keyword: '',
      audio: null,
      index: -1,
      mode: 0,
      currentTime: 0,
      progress: 0,
      isDrag: false,
      volume: 0.5,
      searchList: []
    }
  },

  methods: {
    search() {
      this.searchList.length = 0
      let keyword = this.$refs.search.value.toLowerCase()
      for (let item of this.musicList) {
        if (item.title.toLowerCase().indexOf(keyword) > -1) {
          this.searchList.push(item.id)
        } else if (item.author.toLowerCase().indexOf(keyword) > -1) {
          this.searchList.push(item.id)
        }
      }
    },
    isshow(id) {
      if (this.keyword === '') {
        return true
      } else
        return !!this.searchList.find((value) => {
          return value === id
        });
    },
    play(index) {
      if (index !== this.index) {
        this.audio.currentTime = 0
        this.audio.src = this.baseurl + this.musicList[index].id
        this.index = index
        this.audio.play()
      } else this.audio.play()
      this.$emitter.emit('show')
      this.$store.commit('change', true)
    },
    back() {
      this.play(this.index - 1 < 0 ? this.musicList.length - 1 : (this.index - 1) % this.musicList.length)
    },
    pause() {
      if (this.audio.src) {
        if (this.audio.paused) {
          this.audio.play()
        } else this.audio.pause()
      }

    },
    next() {
      this.play((this.index + 1) % this.musicList.length)
    },
    changeProgress(event) {
      if (this.index !== -1 && event.target.className !== "dot") {
        this.audio.currentTime = event.offsetX / event.currentTarget.clientWidth * this.audio.duration
        this.progress = this.audio.currentTime / this.audio.duration
        this.$store.commit('change', true)
      }
    },
    dot_press() {
      if (this.index >= 0) {
        this.isDrag = true
        document.onmouseup = this.dot_up
        document.onmousemove = this.dragProgress
      }
    },
    dot_up() {
      this.isDrag = false
      document.onmouseup = null
      document.onmousemove = null
      this.audio.currentTime = this.audio.duration * this.progress
      this.$store.commit('change', true)
    },
    dragProgress(event) {
      if (this.isDrag) {
        let progress = (event.clientX - this.$refs.track.getBoundingClientRect().left) / this.$refs.track.clientWidth
        if (progress < 0) {
          progress = 0
        } else if (progress > 1) {
          progress = 1
        }
        this.progress = progress
      }
    },
    repeat(event) {
      this.mode = (this.mode + 1) % 3
      if (this.mode === 0) {
        event.target.className = "icon ri-repeat-one-fill"
      } else if (this.mode === 1) {
        event.target.className = "icon ri-repeat-2-fill"
      } else event.target.className = "icon ri-shuffle-fill"
    },
    name() {
      if (this.index !== -1) {
        return this.musicList[this.index].title + ' - ' + this.musicList[this.index].author
      } else
        return '到点，上号！！！'
    },
    convert(time) {
      if (time) {
        let minute = Math.floor(time / 60)
        let second = Math.floor(time % 60)
        return (minute < 10 ? '0' + minute.toString() : minute) + ':' + (second < 10 ? '0' + second.toString() : second)
      } else return '00:00'
    },
    changevolume() {
      if (this.audio.volume === 0) {
        this.audio.volume = 0.5
      } else if (this.audio.volume === 0.5) {
        this.audio.volume = 0
      } else this.audio.volume = 0.5
    },
    advolume(event) {
      if (event.target.className !== "vdot") {
        this.audio.volume = event.offsetX / event.currentTarget.clientWidth
      }
    },
    vdot_press() {
      document.onmouseup = this.vdot_up
      document.onmousemove = this.dragvolume
    },
    vdot_up() {
      document.onmouseup = null
      document.onmousemove = null
    },
    dragvolume(event) {
      let volue = (event.clientX - this.$refs.vtrack.getBoundingClientRect().left) / this.$refs.vtrack.clientWidth
      if (volue < 0) {
        volue = 0
      } else if (volue > 1) {
        volue = 1
      }
      this.audio.volume = volue
    },
    setMediaControls() {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: this.getTitle,
          artist: this.getArtist,
          album: '',
          artwork: [
            {
              src: 'https://mycloud-1257897713.cos.ap-shanghai.myqcloud.com/images/20220213214031.png'
            }
          ]
        })
        navigator.mediaSession.setActionHandler('previoustrack', this.back);
        navigator.mediaSession.setActionHandler('nexttrack', this.next);
      }
    }
  },
  watch: {
    keyword() {
      if (this.keyword === '') {
        this.searchList.length = 0
      } else this.search()
    },
    progress() {
      this.$store.commit('updateProgress', this.progress)
    },
    index() {
      this.$store.commit('changeTitle', this.musicList[this.index].title + ' - ' + this.musicList[this.index].author)
      navigator.mediaSession.metadata.title = this.getTitle
      navigator.mediaSession.metadata.artist = this.getArtist
    }
  },
  computed: {
    getTitle: function () {
      if (this.index !== -1) {
        return this.musicList[this.index].title
      } else
        return ''
    },
    getArtist: function () {
      if (this.index !== -1) {
        return this.musicList[this.index].author
      } else
        return ''
    }
  },
  created() {
    this.audio = new Audio()
    this.$axios.get('/api/get_musiclist').then(res => {
      this.musicList = res.data
      this.$store.commit('loadComplete')
    })
    this.audio.onended = () => {
      if (this.mode === 0) {
        this.audio.currentTime = 0
        this.audio.play()
      } else if (this.mode === 1) {
        this.index = (this.index + 1) % this.musicList.length
        this.audio.src = this.baseurl + this.musicList[this.index].id
        this.audio.play()
      } else if (this.mode === 2) {
        this.index = Math.floor(Math.random() * this.musicList.length)
        this.audio.src = this.baseurl + this.musicList[this.index].id
        this.audio.play()
      } else {
        this.mode = 0
        this.audio.play()
      }
      this.$store.commit('change', true)
    }
    this.audio.volume = 0.5
    this.audio.onvolumechange = () => {
      this.volume = this.audio.volume
      if (this.audio.volume === 0) {
        this.$refs.volume.className = "icon ri-volume-mute-line"
      } else if (this.audio.volume <= 0.5) {
        this.$refs.volume.className = "icon ri-volume-down-line"
      } else
        this.$refs.volume.className = "icon ri-volume-up-line"
    }
    this.audio.ontimeupdate = () => {
      let count = 5
      let timer = setInterval( ()=> {
        if (this.isDrag === false) {
          this.currentTime = this.audio.currentTime
          this.progress = this.audio.currentTime / this.audio.duration
        }
        if (count === 0){
          clearInterval(timer)
        }else{
          count--;
        }
      },50)
    }
    this.audio.onplay = () => {
      this.$refs.pause.className = "icon ri-pause-fill"
      this.$store.commit('updateState', true)
    }
    this.audio.onpause = () => {
      this.$refs.pause.className = "icon ri-play-fill"
      this.$store.commit('updateState', false)
    }
    this.audio.onwaiting = () => {
      this.$refs.pause.className = "icon ri-loader-2-fill icon-rotation"
      this.$store.commit('setLoading', true)
    }
    this.audio.oncanplay = () => {
      this.$store.commit('setLoading', false)
      if (this.audio.paused) {
        this.$refs.pause.className = "icon ri-play-fill"
      } else this.$refs.pause.className = "icon ri-pause-fill"
    }
    document.onvisibilitychange = () => {
      if (document.visibilityState === 'hidden') {
        this.$store.commit('change', true)
      }
    }
    this.$emitter.on('back', () => {
      this.back()
    })
    this.$emitter.on('pause', () => {
      this.pause()
    })
    this.$emitter.on('next', () => {
      this.next()
    })
  },
  mounted() {
    this.setMediaControls()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.music {
  width: 100%;
  height: 100%;
}

.player {
  height: 100%;
  width: 100%;
}

.searchbar {
  max-height: 50px;
  width: 100%;
  height: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #00000020;
  margin-bottom: 5px;
}

.searchbar input {
  outline: none;
  width: 60%;
  height: 80%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  border: 0;
  font-size: 1.1em;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.4);
}

.searchbar input::-webkit-search-cancel-button {
  display: none;
}

.searchbar input[type=search]::-ms-clear {
  display: none;
}


.info {
  display: flex;
  min-height: 80%;
  max-height: calc(100% - 200px);
  margin-bottom: 5px;
  flex-grow: 1;
}

.music_list {
  /*height: 80%;*/
  width: 100%;
  margin-inline: 8px;
  overflow-y: scroll;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  border-left: 10px solid rgba(255, 255, 255, 0);
}

.music_list::-webkit-scrollbar { /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
}

.music_list::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}

.music_list::-webkit-scrollbar-track { /*滚动条里面轨道*/
  border-radius: 0 10px 10px 0;
  background: rgba(255, 255, 255, 0);
}

.list {
  display: flex;
  height: 60px;
  align-items: center;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.list > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
}

.title {
  left: 3%;
  width: 25%;
}

.performer {
  left: 30%;
  width: 25%;
}

.duration {
  left: 60%;
}

.control {
  height: calc(14% - 11px);
  max-height: 150px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-inline: 8px;
}

.icon {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 30px;
  margin-inline: 15px;
}

.icon-rotation {
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.controlbtn {
  display: flex;
  margin-inline: 20px;
}


.progressbar {
  height: 35px;
  width: 50%;
}

.information {
  position: relative;
  height: 20px;
}

.name {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
  left: 0;
}

.time {
  position: absolute;
  right: 0;
}

.currentTime {

}

.durationTime {

}

.track {
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  margin-block: 6px;
}


.bar {
  width: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.dot {
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  width: 10px;
  height: 10px;
  border-radius: 6px;
  top: 2px;
  transform: translate(-50%, -50%);
  user-select: none
}

.dot:hover {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.volume {
  position: relative;
}

.icon.ri-volume-down-line {
}

.volumebox {
  position: absolute;
  width: 100px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  left: -20px;
  top: -25px;
  border-radius: 10px;
  display: none;
}

.vtrack {
  top: 18px;
  left: 20px;
  height: 4px;
  width: 60%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  position: relative;
}

.vbar {
  position: absolute;
  width: 50%;
  border-radius: 5px;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.vdot {
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  width: 10px;
  height: 10px;
  left: 50%;
  border-radius: 6px;
  top: 2px;
  transform: translate(-50%, -50%);
  user-select: none
}

.vdot:hover {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

#volume:hover .volumebox {
  display: initial;
}
</style>
